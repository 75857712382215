<template>
  <div class="blockslide parallax-demo">
    <div data-v-2f282228="" class="curve-shape curve-bottom" id="bottom-curve"><svg data-v-2f282228="" x="0px" y="0px" viewBox="0 0 3841 120" xml:space="preserve" style="overflow: scroll;"><path data-v-2f282228="" d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                    S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                    S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z" style="fill: rgb(255, 255, 255); stroke-miterlimit: 10;"></path></svg></div>
    <div class="slidecontainer">
        <h2 class="slidetitle">ILS ME FONT CONFIANCE</h2>
        <div class="slider">
                <div class="slide-track">
                    <img class="slideimg" height="100px" src="../assets/totalsup.webp"   alt="Logo client TotalSUP" />
                    <img class="slideimg" height="100px" src="../assets/totalwing.webp"   alt="Logo client TotalWing" />
                    <img class="slideimg" height="100px" src="../assets/happyneuron.webp"   alt="Logo client HappyNeuron Academy" /> 
                    <img class="slideimg" height="100px" src="../assets/notaire.webp"   alt="Logo client Maitre Montagner Notaire" />
                    <img class="slideimg" height="100px" src="../assets/superbus.webp"   alt="Logo client Superbus" />
                    <img class="slideimg" height="100px" src="../assets/bozoyan.webp"   alt="Logo client Bozoyan" />                    
                    <img class="slideimg" height="100px" src="../assets/activague.webp"   alt="Logo client Activague" />
                    <img class="slideimg" height="100px" src="../assets/maisonjaune.webp"   alt="Logo client La Maison Jaune" />
                    <img class="slideimg" height="100px" src="../assets/assoyoga.webp"   alt="Logo client Asso Yoga Façon d'Être" />
                    <img class="slideimg" height="100px" src="../assets/sblr.webp"   alt="Logo client Stade Beaumontois Lomagne Rugby" />
                    <img class="slideimg" height="100px" src="../assets/brienneimmo.webp"   alt="Logo client Brienne Immobillier" />   
                    <img class="slideimg" height="100px" src="../assets/pdc.webp"   alt="Logo client Plume de Carotte" />
                    <img class="slideimg" height="100px" src="../assets/totalsup.webp"   alt="Logo client TotalSUP" />
                    <img class="slideimg" height="100px" src="../assets/totalwing.webp"   alt="Logo client TotalWing" />
                    <img class="slideimg" height="100px" src="../assets/happyneuron.webp"   alt="Logo client HappyNeuron Academy" /> 
                    <img class="slideimg" height="100px" src="../assets/notaire.webp"   alt="Logo client Maitre Montagner Notaire" />
                    <img class="slideimg" height="100px" src="../assets/superbus.webp"   alt="Logo client Superbus" />
                    <img class="slideimg" height="100px" src="../assets/bozoyan.webp"   alt="Logo client Bozoyan" />                    
                    <img class="slideimg" height="100px" src="../assets/activague.webp"   alt="Logo client Activague" />
                    <img class="slideimg" height="100px" src="../assets/maisonjaune.webp"   alt="Logo client La Maison Jaune" />
                    <img class="slideimg" height="100px" src="../assets/assoyoga.webp"   alt="Logo client Asso Yoga Façon d'Être" />
                    <img class="slideimg" height="100px" src="../assets/sblr.webp"   alt="Logo client Stade Beaumontois Lomagne Rugby" />
                    <img class="slideimg" height="100px" src="../assets/brienneimmo.webp"   alt="Logo client Brienne Immobillier" />   
                    <img class="slideimg" height="100px" src="../assets/pdc.webp"   alt="Logo client Plume de Carotte" />
                    <img class="slideimg" height="100px" src="../assets/totalsup.webp"   alt="Logo client TotalSUP" />
                    <img class="slideimg" height="100px" src="../assets/totalwing.webp"   alt="Logo client TotalWing" />
                    <img class="slideimg" height="100px" src="../assets/happyneuron.webp"   alt="Logo client HappyNeuron Academy" /> 
                    <img class="slideimg" height="100px" src="../assets/notaire.webp"   alt="Logo client Maitre Montagner Notaire" />
                    <img class="slideimg" height="100px" src="../assets/superbus.webp"   alt="Logo client Superbus" />
                    <img class="slideimg" height="100px" src="../assets/bozoyan.webp"   alt="Logo client Bozoyan" />                    
                    <img class="slideimg" height="100px" src="../assets/activague.webp"   alt="Logo client Activague" />
                    <img class="slideimg" height="100px" src="../assets/maisonjaune.webp"   alt="Logo client La Maison Jaune" />
                    <img class="slideimg" height="100px" src="../assets/assoyoga.webp"   alt="Logo client Asso Yoga Façon d'Être" />
                    <img class="slideimg" height="100px" src="../assets/sblr.webp"   alt="Logo client Stade Beaumontois Lomagne Rugby" />
                    <img class="slideimg" height="100px" src="../assets/brienneimmo.webp"   alt="Logo client Brienne Immobillier" />   
                    <img class="slideimg" height="100px" src="../assets/pdc.webp"   alt="Logo client Plume de Carotte" />
                    <img class="slideimg" height="100px" src="../assets/totalsup.webp"   alt="Logo client TotalSUP" />
                    <img class="slideimg" height="100px" src="../assets/totalwing.webp"   alt="Logo client TotalWing" />
                    <img class="slideimg" height="100px" src="../assets/happyneuron.webp"   alt="Logo client HappyNeuron Academy" /> 
                    <img class="slideimg" height="100px" src="../assets/notaire.webp"   alt="Logo client Maitre Montagner Notaire" />
                    <img class="slideimg" height="100px" src="../assets/superbus.webp"   alt="Logo client Superbus" />
                    <img class="slideimg" height="100px" src="../assets/bozoyan.webp"   alt="Logo client Bozoyan" />                    
                    <img class="slideimg" height="100px" src="../assets/activague.webp"   alt="Logo client Activague" />
                    <img class="slideimg" height="100px" src="../assets/maisonjaune.webp"   alt="Logo client La Maison Jaune" />
                    <img class="slideimg" height="100px" src="../assets/assoyoga.webp"   alt="Logo client Asso Yoga Façon d'Être" />
                    <img class="slideimg" height="100px" src="../assets/sblr.webp"   alt="Logo client Stade Beaumontois Lomagne Rugby" />
                    <img class="slideimg" height="100px" src="../assets/brienneimmo.webp"   alt="Logo client Brienne Immobillier" />   
                    <img class="slideimg" height="100px" src="../assets/pdc.webp"   alt="Logo client Plume de Carotte" />
                    <img class="slideimg" height="100px" src="../assets/totalsup.webp"   alt="Logo client TotalSUP" />
                    <img class="slideimg" height="100px" src="../assets/totalwing.webp"   alt="Logo client TotalWing" />
                    <img class="slideimg" height="100px" src="../assets/happyneuron.webp"   alt="Logo client HappyNeuron Academy" /> 
                    <img class="slideimg" height="100px" src="../assets/notaire.webp"   alt="Logo client Maitre Montagner Notaire" />
                    <img class="slideimg" height="100px" src="../assets/superbus.webp"   alt="Logo client Superbus" />
                    <img class="slideimg" height="100px" src="../assets/bozoyan.webp"   alt="Logo client Bozoyan" />                    
                    <img class="slideimg" height="100px" src="../assets/activague.webp"   alt="Logo client Activague" />
                    <img class="slideimg" height="100px" src="../assets/maisonjaune.webp"   alt="Logo client La Maison Jaune" />
                    <img class="slideimg" height="100px" src="../assets/assoyoga.webp"   alt="Logo client Asso Yoga Façon d'Être" />
                    <img class="slideimg" height="100px" src="../assets/sblr.webp"   alt="Logo client Stade Beaumontois Lomagne Rugby" />
                    <img class="slideimg" height="100px" src="../assets/brienneimmo.webp"   alt="Logo client Brienne Immobillier" />   
                    <img class="slideimg" height="100px" src="../assets/pdc.webp"   alt="Logo client Plume de Carotte" />
                    <img class="slideimg" height="100px" src="../assets/totalsup.webp"   alt="Logo client TotalSUP" />
                    <img class="slideimg" height="100px" src="../assets/totalwing.webp"   alt="Logo client TotalWing" />
                    <img class="slideimg" height="100px" src="../assets/happyneuron.webp"   alt="Logo client HappyNeuron Academy" /> 
                    <img class="slideimg" height="100px" src="../assets/notaire.webp"   alt="Logo client Maitre Montagner Notaire" />
                    <img class="slideimg" height="100px" src="../assets/superbus.webp"   alt="Logo client Superbus" />
                    <img class="slideimg" height="100px" src="../assets/bozoyan.webp"   alt="Logo client Bozoyan" />                    
                    <img class="slideimg" height="100px" src="../assets/activague.webp"   alt="Logo client Activague" />
                    <img class="slideimg" height="100px" src="../assets/maisonjaune.webp"   alt="Logo client La Maison Jaune" />
                    <img class="slideimg" height="100px" src="../assets/assoyoga.webp"   alt="Logo client Asso Yoga Façon d'Être" />
                    <img class="slideimg" height="100px" src="../assets/sblr.webp"   alt="Logo client Stade Beaumontois Lomagne Rugby" />
                    <img class="slideimg" height="100px" src="../assets/brienneimmo.webp"   alt="Logo client Brienne Immobillier" />   
                    <img class="slideimg" height="100px" src="../assets/pdc.webp"   alt="Logo client Plume de Carotte" />
                    <img class="slideimg" height="100px" src="../assets/totalsup.webp"   alt="Logo client TotalSUP" />
                    <img class="slideimg" height="100px" src="../assets/totalwing.webp"   alt="Logo client TotalWing" />
                    <img class="slideimg" height="100px" src="../assets/happyneuron.webp"   alt="Logo client HappyNeuron Academy" /> 
                    <img class="slideimg" height="100px" src="../assets/notaire.webp"   alt="Logo client Maitre Montagner Notaire" />
                    <img class="slideimg" height="100px" src="../assets/superbus.webp"   alt="Logo client Superbus" />
                    <img class="slideimg" height="100px" src="../assets/bozoyan.webp"   alt="Logo client Bozoyan" />                    
                    <img class="slideimg" height="100px" src="../assets/activague.webp"   alt="Logo client Activague" />
                    <img class="slideimg" height="100px" src="../assets/maisonjaune.webp"   alt="Logo client La Maison Jaune" />
                    <img class="slideimg" height="100px" src="../assets/assoyoga.webp"   alt="Logo client Asso Yoga Façon d'Être" />
                    <img class="slideimg" height="100px" src="../assets/sblr.webp"   alt="Logo client Stade Beaumontois Lomagne Rugby" />
                    <img class="slideimg" height="100px" src="../assets/brienneimmo.webp"   alt="Logo client Brienne Immobillier" />   
                    <img class="slideimg" height="100px" src="../assets/pdc.webp"   alt="Logo client Plume de Carotte" />
                </div>
        </div>
        <router-link to="Portfolio" class="slidelink">DÉCOUVREZ TOUTES MES RÉALISATIONS</router-link>
    </div>
    <div data-v-2f282229="" class="curve-shape" id="top-curve">
      <svg data-v-2f282229="" x="0px" y="0px" viewBox="0 0 3841 120" xml:space="preserve" style="overflow: scroll;">
        <path data-v-2f282229="" d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                    S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                    S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z" style="fill: rgb(255, 255, 255); stroke-miterlimit: 10;"></path>
        </svg>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Header',
  props: {
    msg: String
  },
    mounted() {
      $.fn.parallax = function(opt) {
    return this.each(function() {
        var optDefault = {
                speed: 0.3
            },
            self = $(this),
            optDataSpeed = {
                speed: self.data('parallax-speed')
            },
            bpFirst = self.css('background-position').split(' ')[0],
            bpLast = self.css('background-position').split(' ')[1],
            opts = $.extend(optDefault, opt, optDataSpeed);
        function parallax() {
            var st = $(window).scrollTop(),
                wh = $(window).outerHeight(),
                o = self.offset(),
                h = self.outerHeight(),
                bp = bpFirst + ' ' + 'calc(' + bpLast + ' + ' + (o.top-st)*opts.speed + 'px)';
            if ( (st + wh >= o.top) && (st <= o.top + h) ) {
                self.css('background-position', bp);
            }
        }
        $(window).on('scroll', parallax);
    });
    }

    // Demo
    $('.parallax-demo').parallax({
        speed: 0.4
    });
    }
}
</script>

<style lang="scss">

@keyframes move-left-data-v-2f282228 {
    0% {
        left: 0;
    }
    to {
        left: -100%;
    }
}

@keyframes move-left-data-v-2f282229 {
    0% {
        left: 0;
    }
    to {
        left: -100%;
    }
}

.blockslide {
  position: relative;
  background-image: url("../assets/seignosse6.webp");
  background-size: cover;
  background-position: center;
  height: 500px;
  margin-bottom: 40px;  
  @media (max-width: 1440px) {
		height: 450px;
	}
  @media (max-width: 979px) {
		height: 400px;
	}
  @media (max-width: 768px) {
		height: 450px;
	}
  @media (max-width: 425px) {
		height: 400px;
	}
  @media (max-width: 300px) {
		height: 450px;
	}
    @media (min-width: 1650px) {
		height: 650px;
	}
}

.curve-shape svg[data-v-2f282228] {
    width: 200%;
    position: relative;
    animation: move-left-data-v-2f282228 6s linear infinite;
}

.curve-shape svg[data-v-2f282229] {
    width: 200%;
    position: relative;
    animation: move-left-data-v-2f282229 5s linear infinite;
}

.curve-shape.curve-bottom {
    overflow: hidden;
    transform: rotateX(-180deg);
    margin-bottom: -160px!important;
}

.curve-shape[data-v-2f282228] {
    overflow-x: hidden;
}

.curve-shape[data-v-2f282229] {
    margin-bottom: -12px;
    overflow-x: hidden;
}

#bottom-curve {
    position: relative;
    top: -20px;
    margin-bottom: 0 !important;
    @media (max-width: 1440px) {
		top: -14px;
	}
    @media (max-width: 768px) {
		top: -10px;
	}
    @media (max-width: 425px) {
		top: -8px;
	}
     @media (max-width: 300px) {
		top: -6px;
	}
    @media (min-width: 1650px) {
		top: -24px;
	}
    
}

#top-curve {
  position: absolute;
    bottom: -8px;
    width: 100%;
    @media (max-width: 1440px) {
		bottom: -3px;
	}
    @media (max-width: 768px) {
		bottom: 3px;
	}
    @media (max-width: 425px) {
		bottom: 4px;
	}
    @media (min-width: 1650px) {
		bottom: -10px;
	}

}

.slidetitle {
    font-family: 'Yanone Kaffeesatz';
    text-align: center;
    font-size: xxx-large;
    color: white;
    margin-top: 20px;
}

@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 90s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 30))}
}


// Styling
.slider {
	height: auto;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 80%;
	margin-top: 45px;
	@media (max-width: 768px) {
		width: 100%;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 30);
	}
	
	.slideimg {
		height: 100px;
		width: auto;
        margin-right: 40px;
	}
}

.slidelink {
    display: block;
    font-family: 'Yanone Kaffeesatz';
    text-align: center;
    font-size: large;
    color: white!important;
    margin: auto;
    margin-top: 60px;
    transition: all 0.25s;
    width: fit-content;
    box-sizing: border-box;
    height: max-content;
    &:hover {
        transform: scale(1.03);
    }
}


</style>